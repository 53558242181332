import { ReactNode } from 'react';
// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// // import { APP_NAMES_ENUM } from 'src/@types/enums';
// import LoadingScreen from 'src/components/LoadingScreen';
// import useCurrentUser from 'src/hooks/useCurrentUser';

type StripeProviderProps = {
  children: ReactNode;
};

function StripeProvider({ children }: StripeProviderProps) {
  // const { appName, initialized } = useCurrentUser();

  // Memoize stripePromise to avoid re-initializing on every render
  // const stripePromise = useMemo(() => {
  //   const stripeKey =
  //     appName === APP_NAMES_ENUM.FITPROS
  //       ? process.env.REACT_APP_FITPROS_STRIPE_KEY
  //       : process.env.REACT_APP_STRIPE_KEY;
  //   // const stripeKey = process.env.REACT_APP_FITPROS_STRIPE_KEY;

  //   return loadStripe(stripeKey as string);
  // }, [appName]);

  // if (!initialized) {
  //   return <LoadingScreen />;
  // }

  const stripePromise = loadStripe(process.env.REACT_APP_FITPROS_STRIPE_KEY as string);

  return <Elements stripe={stripePromise}>{children}</Elements>;
}

export { StripeProvider };
