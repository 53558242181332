import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
// components
import {
  FormProvider,
  RHFTextField,
  RHFAutocomplete,
  RHFYouTubeField,
} from 'src/components/hook-form';
import YouTube from 'react-youtube';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { useDispatch, useSelector } from 'src/redux/store';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { saveExercise } from 'src/redux/slices/exercises';
import {
  fetchMuscleTags,
  getMuscleTagsFetchStatus,
  selectAllMuscleTags,
} from 'src/redux/slices/muscleTags';
import {
  fetchMovementTags,
  getMovementTagsFetchStatus,
  selectAllMovementTags,
} from 'src/redux/slices/movementTags';
import { getYouTubeId } from 'src/utils/getYouTubeId';
import {
  DefaultExerciseMetric,
  Exercise_WithID,
  MovementTag_WithID,
  MuscleTag_WithID,
} from 'src/@types/firebase';
import useExerciseMetrics from 'src/hooks/useExerciseMetrics';

// ----------------------------------------------------------------------
const VIDEO_HEIGHT = 390;

// eslint-disable-next-line arrow-body-style
const YouTubeSkeleton = ({ error = false }: { error?: boolean }) => {
  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: VIDEO_HEIGHT, backgroundColor: 'background.neutral' }}
    >
      <Iconify icon={'fluent:video-clip-24-filled'} width={50} height={50} />
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        {error ? 'Invalid YouTube url' : 'No video'}
      </Typography>
    </Stack>
  );
};

// ----------------------------------------------------------------------

export type ExerciseFormValuesProps = {
  id?: string;
  name: string;
  description: string;
  youtubeURL?: string;
  youtubeId: string;
  thumbnailUrl: string;
  defaultExerciseMetrics: DefaultExerciseMetric[];
  defaultExerciseMetricIds: string[];
  movementTags: MovementTag_WithID[];
  movementTagIds: string[];
  muscleTags: MuscleTag_WithID[];
  muscleTagIds: string[];
};

type Props = {
  isEdit?: boolean;
  currentExercise?: Exercise_WithID;
  onSave?: (exercise: Exercise_WithID) => void;
};

export default function ExerciseNewEditForm({ isEdit, currentExercise, onSave }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), [navigate]);

  const { enqueueSnackbar } = useSnackbar();
  const [youtubeError, setYoutubeError] = useState(false);
  const { exerciseMetrics } = useExerciseMetrics();
  const muscleTags = useSelector(selectAllMuscleTags);
  const movementTags = useSelector(selectAllMovementTags);
  const muscleTagsFetchStatus = useSelector(getMuscleTagsFetchStatus);
  const movementTagsFetchStatus = useSelector(getMovementTagsFetchStatus);

  const videoOptions: any = {
    height: VIDEO_HEIGHT,
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const NewExerciseSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    defaultExerciseMetrics: Yup.array().min(1, 'You must choose at least one metric'),
  });

  const defaultValues = useMemo(
    () => ({
      id: currentExercise?.id ? currentExercise.id : '',
      name: currentExercise?.name || '',
      description: currentExercise?.description || '',
      youtubeURL: currentExercise?.youtubeId
        ? `https://www.youtube.com/watch?v=${currentExercise.youtubeId}`
        : '',
      defaultExerciseMetrics: currentExercise?.defaultExerciseMetrics || [],
      movementTags: currentExercise?.movementTags || [],
      muscleTags: currentExercise?.muscleTags || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentExercise]
  );

  const methods = useForm<ExerciseFormValuesProps>({
    resolver: yupResolver(NewExerciseSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  const youtubeId = values?.youtubeURL ? getYouTubeId(values.youtubeURL) : '';
  console.log('youtubeId', youtubeId);
  const thumbnailUrl = youtubeId ? `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg` : '';
  const defaultExerciseMetricIds = values.defaultExerciseMetrics.map((metric) => metric.id);
  const movementTagIds = values.movementTags.map((tag) => tag.id);
  const muscleTagIds = values.muscleTags.map((tag) => tag.id);

  useEffect(() => {
    if (!muscleTags.length && muscleTagsFetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchMuscleTags());
    }
    if (!movementTags.length && movementTagsFetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchMovementTags());
    }
  }, [
    muscleTags.length,
    muscleTagsFetchStatus,
    movementTags.length,
    movementTagsFetchStatus,
    dispatch,
  ]);

  useEffect(() => {
    if (isEdit && currentExercise) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentExercise]);

  const onSubmit = async (data: ExerciseFormValuesProps) => {
    const formData = {
      ...data,
      youtubeId,
      thumbnailUrl,
      defaultExerciseMetricIds,
      movementTagIds,
      muscleTagIds,
    };
    delete formData.youtubeURL;

    try {
      const savedExercise = (await (
        await dispatch(saveExercise({ formData, isEdit: isEdit || false }))
      ).payload) as Exercise_WithID;

      if (onSave) {
        onSave(savedExercise);
      }
      // !!(isAddModal && savedExercise) && dispatch(toggleExerciseSelected(savedExercise));
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');

      if (!onSave) {
        back();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading={isEdit ? 'Edit exercise' : 'New exercise'}
        links={
          isEdit
            ? [
                {
                  name: 'Exercises',
                  onClick: () => window.history.go(-2),
                },
                { name: currentExercise?.name ? currentExercise.name : 'Exercise', onClick: back },
                { name: 'Edit' },
              ]
            : [
                {
                  name: 'Exercises',
                  onClick: back,
                },
                { name: 'New' },
              ]
        }
        action={
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            startIcon={<Iconify icon={'bxs:save'} />}
          >
            {!isEdit ? 'Create Exercise' : 'Save Changes'}
          </Button>
        }
      />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ overflow: 'visible' }}>
          <Image
            src={thumbnailUrl}
            alt="Youtube thumbnail"
            onError={() => {
              console.error('ERROR');
              setYoutubeError(true);
            }}
            sx={{ width: 0, height: 0 }}
          />

          <Box
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              overflow: 'hidden',
              minHeight: VIDEO_HEIGHT,
            }}
          >
            {youtubeId && !youtubeError ? (
              <YouTube opts={videoOptions} videoId={youtubeId} />
            ) : (
              <YouTubeSkeleton error={youtubeError} />
            )}
          </Box>

          <Stack sx={{ p: 3 }} spacing={3}>
            <RHFTextField name="name" label="Exercise Name" />

            <RHFTextField name="description" label="Description" multiline />

            <RHFYouTubeField name="youtubeURL" label="Youtube URL" />

            <RHFAutocomplete
              name="defaultExerciseMetrics"
              label="Metrics"
              options={exerciseMetrics}
            />

            <RHFAutocomplete name="muscleTags" label="Muscles" options={muscleTags} />

            <RHFAutocomplete name="movementTags" label="Movement" options={movementTags} />

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit ? 'Create Exercise' : 'Save Changes'}
            </LoadingButton>
          </Stack>
        </Card>
      </FormProvider>
    </>
  );
}
