import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { List, Collapse, Link } from '@mui/material';
//
import { NavListProps } from '../type';
import NavItem from './NavItem';
import { getActive, isExternalLink } from '..';
import { useSelector } from 'src/redux/store';
import { getUserCustomMobileAppEnabled, getUserType } from 'src/redux/slices/user';
import { USER_TYPE_ENUM } from 'src/@types/enums';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  isCollapse?: boolean;
};

export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
}: NavListRootProps) {
  const navigate = useNavigate();

  const userType = useSelector(getUserType);

  const { pathname } = useLocation();

  const active = getActive(data.path, pathname);

  const [open, setOpen] = useState(active);

  const handleClickItem = () => {
    if (!hasChildren) {
      navigate(data.path);
    }
    setOpen(!open);
  };

  // Hide nav item if user type is not in access list
  if (data?.access && !data.access.some((userAccess) => userType?.includes(userAccess))) {
    return null;
  }

  // Hide custom app nav item if user does not have custom app enabled
  // if (
  //   data?.path === '/dashboard/mobile-app' &&
  //   !userHasCustomAppEnabled &&
  //   !userType?.includes(USER_TYPE_ENUM.COACH_LYDIA_ADMIN)
  // ) {
  //   return null;
  // }

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target="_blank" rel="noopener" underline="none">
          <NavItem item={data} depth={depth} open={open} active={active} isCollapse={isCollapse} />
        </Link>
      ) : (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
        />
      )}

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} userType={userType} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  userType?: USER_TYPE_ENUM[];
};

function NavSubList({ data, depth, userType }: NavListSubProps) {
  return (
    <>
      {data
        .filter((list) =>
          list?.access ? list.access.some((userAccess) => userType?.includes(userAccess)) : true
        )
        .map((list) => (
          <NavList
            key={list.title + list.path}
            data={list}
            depth={depth + 1}
            hasChildren={!!list.children}
          />
        ))}
    </>
  );
}
